import { url } from '../../../link/Url.link';

export const GetOf = () => {
  return new Promise(async function(resolve, reject) {
    try {
      const query = await fetch(url+'/chart_filter_of',{
        method: 'POST',
        headers: {
          'authorization': "marcuxo",
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })

        const reqkkst = await query.json();
        // console.log(reqkkst)
        
        if(reqkkst.success){
          resolve(reqkkst.body)
          // alert('La operacion fue realizada con exito!!');
        }else{
          resolve(reqkkst.error)
          // alert('Upss!! ocurrio un error vuelve a intentarlo');
        }
      } catch (error) {
        resolve(null)
      }
  })
}
