import React from 'react'
import { Box, Button, Drawer, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { useState } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { DownloadFileData } from '../../components/DownloadFileData';
import { url, url_server } from '../../link/Url.link';


const now = new Date();
const yesterdayBegin = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
const todayEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);

export const DownloadFile_DB = () => {
  const [drawSate, setDrawSate] = useState(false);
  const [value, onChange] = useState([yesterdayBegin, todayEnd]);
  const [file, setfile] = useState(null)

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawSate(open);
  };

  const DownloadFile = async () => {
    const response = await DownloadFileData({value})
    setfile(response.body)
    setDrawSate(false)
    // console.log(response);
    //method to download file
    let url = response.body;
    let a = document.createElement("a");
    a.href = url_server+url;//to web add url_server+build/
    a.href = url;
    a.download = "Control_Inyeccion.xlsx";
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
  const DownloadFile_web = async () => {
    let hoster = window.navigator.userAgent;
    if(hoster.match(/Android/i) || hoster.match(/iPhone/i)){
      await DownloadFile()
    }else{
      window.location.href = url+`/downloadfile_get/${value[0].toISOString()}/${value[1].toISOString()}`
      setDrawSate(false)
    }
    
  }


  return (
    <>
      {/* <Button variant='contained' size='small' onClick={toggleDrawer(true)}><PersonAddAlt1Icon /> <span className='px-1'> </span>Agreagar Usuario</Button> */}

      <span className='hoverClick-sw text-warning' onClick={toggleDrawer(true)} >Reporte</span>

      <Drawer
        anchor={'bottom'}
        open={drawSate}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width:'auto', height: '100vh'}}
          role="presentation"
        >
          <div className='container-fluid pt-3 pb-3'>
            <div className='row'>
              <div className='col-12'>
                <h4 className='display-5'>Reporte Control de Inyeccion</h4>
              </div>
              
              <div className='col-12 col-md-8'>
                <h5 className='lead'>Selecciona un rango de fechas</h5>
                <DateRangePicker
                  calendarAriaLabel="Toggle calendar"
                  clearAriaLabel="Clear value"
                  dayAriaLabel="Day"
                  monthAriaLabel="Month"
                  nativeInputAriaLabel="Date"
                  yearAriaLabel="Year"
                  locale='ES-cl'
                  value={value}
                  onChange={onChange}
                />
              </div>
              <div className='col-12 col-md-4 mt-2 text-right pt-3'>
                <div className='row text-center'>
                  <div className='col-12 col-md-6 py-3'>
                    <Button
                      variant='contained'
                      color='error'
                      onClick={()=>setDrawSate(false)}
                    >Cancelar</Button>
                  </div>
                  <div className='col-12 col-md-6'>
                    <Button
                      variant='contained'
                      color='success'
                      onClick={()=>DownloadFile_web()}
                    >Descargar Reporte</Button> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Drawer>
    </>
  )
}
