import React, {useContext, useEffect, useState} from 'react'
import { url } from '../../link/Url.link'
import ReplyIcon from '@mui/icons-material/Reply';
import ListSubheader from '@mui/material/ListSubheader';
import './seleccion.css'
import { Navigate, useNavigate } from "react-router-dom"
import { Alert, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { GetOfs } from '../../components/GetOfs';
import DataContext from '../../context/Data/DataContext';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';

export const Seleccion = () => {

  const {state, getData, setData} = useContext(DataContext);
  const navigate = useNavigate();
  const [theData, setTheData] = useState({
    linea: '' || sessionStorage.getItem('linea'),
    equipo: '' || sessionStorage.getItem('equipo'),
    usuario: '' || sessionStorage.getItem('usuario')
  });
  const [ofList, setOfList] = useState([]);
  const [dirImg, setdirImg] = useState('');
  const [OFecha,setOFecha] = useState({
    of:'', fecha:''
  });
  const [isSelectOf, setIsSelectOf] = useState(false)

  const queryFetch = async () => {
    const query = await fetch(url+'/getofs',
    {
      method: 'POST',
      headers: 
      {
        'authorization': "paico 2021",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({maquina: theData.linea})
    })

    const recuest = await query.json()
    // console.log(recuest.body)
  }

  const getDirImg = () => {
    let dir = './img/'+theData.linea+'.png'
    setdirImg(dir)
  }

  const GetOfs_ = async () => {
    let LOF = await GetOfs({theData});
    // console.log(LOF)
    await setOfList(LOF)
    // await setOfList(GetOfs());
  }

  const HandleChangeSelect = async (data) => {
    let values = data.split(' ')
    // console.log('values=>', values)

    setOFecha({
      of:values[0],
      fecha:values[1]
    })
  }

  useEffect(() => {
    if(OFecha.fecha !== '' && OFecha.of !== '')setIsSelectOf(true)
    else setIsSelectOf(false)
  }, [OFecha])
  

  useEffect(() => {
    queryFetch();
    GetOfs_();
    getDirImg();
  }, [])

  useEffect(() => {
    // console.log(theData)
    // console.log('Seleccion EFECT',sessionStorage.getItem('linea'))
    setData({
      linea: sessionStorage.getItem('linea'),
      equipo: sessionStorage.getItem('equipo'),
      usuario: sessionStorage.getItem('usuario')
    });
  }, [])
  

  const HandleLetSelecction = async () => {
    // setData(theData)
    setData(Object.assign({},OFecha, theData))
    navigate("/ingreso")
  }

  const toThePast = async () => {
    navigate("../", { replace: true });
  }
  
  return (
    <>
      <div className='container-fluid mb-4'>
        <div className='row hdr-nav'>
          <div className='col-5 col-md-4 centro'>
            <span className="px-3 hoverClick" onClick={()=>toThePast()}><ReplyIcon /> volver</span>
          </div>
          <div className='col-1 col-md-4 hidden-xs text-center'>
            <img src="img/logo_ariztia.png" className='cursor' alt='Logo Ariztia' height={'50px'} onClick={()=>navigate("/", { replace: true })} />
          </div>
          <div className='col-7 col-md-4 text-right txt-user'>
            <span className="px-3 py-2 ">Usuario: {state.data.usuario}</span>
          </div>
        </div>
      </div>

      <div className='fondo container divmenu1 py-5'>
        <div className='row'>
          <div className='col-12 col-md-6 '>
            <div className='row'>
              <div className='col-12 text-center'>
                <h5>{theData.equipo}</h5>
              </div>
              <div className='col-12'>
                
              </div>
              <div className='col-12 text-center py-2'>
                <img className='img-fluido' src={dirImg} />
              </div>
              <div className='col-12 fondo p-3' style={{borderRadius:10}}>
                <FormControl fullWidth size='small'>
                  <InputLabel htmlFor="grouped-native-select">Seleccione OF</InputLabel>
                  <Select
                      native
                      defaultValue=""
                      id="grouped-native-select"
                      label="Seleccione OF"
                      onChange={(e)=>HandleChangeSelect(e.target.value)}
                  >
                    <option aria-label="None" value="" />
                    {
                      ofList?
                        ofList.map((itm, index)=>(

                          <optgroup key={index} style={{fontSize: '12px'}} label={itm._id}>{itm._id}
                          {
                            itm['of'].map((itmm, index)=><option key={index} style={{fontSize: '12px'}} value={itmm}>{itmm}</option>)
                          }
                          </optgroup>
                        ))
                      :null
                    }
                    
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className='col-12 centro col-md-6'>
            <div className='row'>
              <div className='col-12 p-2'>
                <Button variant='contained' size='large' color='success' disabled={!isSelectOf} fullWidth onClick={()=>HandleLetSelecction()}> Ingresar Control Inyeccion <SaveAsOutlinedIcon /></Button>
              </div>
              <div className='col-12 p-2'>
                <Button variant='contained' size='large' fullWidth onClick={()=>navigate("../", { replace: true })}> cambiar usuario o maquina <ManageAccountsOutlinedIcon /></Button>
              </div>
              <div className='col-12 p-2'>
                <Button variant='contained' size='large' fullWidth onClick={()=>navigate('/carga')}> cArGar Datos desde arChivo<AttachFileOutlinedIcon /></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
