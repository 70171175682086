import React, { useContext, useEffect, useState } from 'react'
import LogoutIcon from '@mui/icons-material/Logout';
import LoginContext from '../../context/Login/LoginContext';
import { useNavigate } from 'react-router-dom';
import './dashBoard.css'
import ViewPage from './ViewPage.view';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import HouseIcon from '@mui/icons-material/House';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { DownloadFile_DB } from './DownloadFile_DB';

export const DashBoard = () => {

  const {LoginState, SetLogin, CloseLogin, SetLoginDirect} = useContext(LoginContext);
  const [ViewNow, setViewNow] = useState(null)
  const navigate = useNavigate();

  const HandleCloseSession = async () => {
    alert(`Hasta pronto ${LoginState.user}. vuelva pronto.`);
    await CloseLogin({user:'no-user'},null)
    navigate('/login');
  }

  useEffect(() => {
    // if(LoginState.user === 'no-user'){
    //   navigate('/login');
    // }
    const isthis = async () => {
      const isSession = await SetLoginDirect();
      if(!isSession){
          navigate('/login');
        }
    }
    isthis()
    
  }, [])

  return (
    <>
      <div className='container-fluid'>
        <div className='row hdr-nav'>
          <div className='col-5 col-md-3 hidden-xs centro'>
            <span className="px-3"></span>
          </div>
          <div className='col-3 col-md-4 text-center'>
            <img src="img/logo_ariztia.png" className='cursor' onClick={()=>navigate('/')} alt='Logo Ariztia' height={'50px'}/>
          </div>
          <div className='col-7 col-md-4 text-right txt-user'>
            <span className="px-3 py-2 ">Usuario: {LoginState.user}</span>
          </div>
          <div className='col-2 col-md-1 text-right txt-user'>
            <span
              className="hoverClick"
              onClick={()=>HandleCloseSession()}
              title="Salir 🖐🏽">
                <LogoutIcon color='#000000' />
            </span>
          </div>
        </div>
      </div>
      
      <div className='container'>
        <div className='row'>
          <div className='col pb-4 text-center'>
            {/* buttons pages */}
            <span className='hoverClick-sw text-warning' onClick={()=>setViewNow(null)}> Home </span>
            <span className='hoverClick-sw text-warning' onClick={()=>setViewNow('crud-user')}> Usuario </span>
            <span className='hoverClick-sw text-warning' onClick={()=>setViewNow('productos')}> Productos </span>
            <DownloadFile_DB />
          </div>
        </div>
        </div>
      {/* renders pages intro this */}
      <ViewPage ViewNow={ViewNow} />
    </>
  )
}


