import { BrowserRouter, Routes,Route, HashRouter } from 'react-router-dom'
import DataState from './context/Data/DataState';
import LoginState from './context/Login/LoginState';
import { DashBoard } from './view/admin/DashBoard.view';
import { Login } from './view/admin/Login.view';
import { CargaArchivo } from './view/inyeccion/CargaArchivo';
import { Ingreso } from './view/inyeccion/Ingreso';
import { Seleccion } from './view/inyeccion/Seleccion';
import { Main } from './view/Main';

function App() {
  return (
    <>
    <LoginState>
      <DataState>
        {/* IT WORK IN BUILD ADD TO SERVER */}
        <HashRouter>
          <Routes >
            <Route path='/' element={<Main />} />
            <Route path='/seleccion' element={<Seleccion />} />
            <Route path='/ingreso' element={<Ingreso />} />
            <Route path='/carga' element={<CargaArchivo />} />
            {/* session */}
            <Route path='/login' element={<Login />} />
            <Route path='/dashboard' element={<DashBoard />} />

            <Route path='*' element={<Main />} />
          </Routes>
        </HashRouter>
      </DataState>
    </LoginState>
    </>
  );
}

export default App;
