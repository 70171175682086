import { Button, Drawer, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import { GetRutData } from '../../../components/GetRutData';
import { SaveUserInyect } from '../../../components/SaveUserInyect';
import LoginContext from '../../../context/Login/LoginContext';


// icons
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export const AddUser = ({setisReload,isReload}) => {
  const {LoginState, SetLogin, CloseLogin, SetLoginDirect} = useContext(LoginContext);
  const [drawSate, setDrawSate] = useState(false);
  const [newUser, setnewUser] = useState({
    rut: '',
    nombre: '',
    ceco:'',
    boosLine: LoginState.user,
    maquina:''
  });
  const [isRut, setIsRut] = useState(false);
  const [isComplete, setIsComplete] = useState(true)

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawSate(open);
  };

  const formatRut = async (e) => {
    var clean = e.target.value.replace(/-/g,"")
    var largo = clean.length
    var prin = clean.substr(0,(largo-1));
    var nal = clean.substr((largo-1),largo)
    let data = prin+"-"+nal
    setnewUser({
      ...newUser,
      ['rut']: data
    })
  }

  const verifiRut = async () => {
    if(newUser.rut.length >=9){
      let retrn = await GetRutData(newUser.rut)
      if(retrn){
        // console.log('ENCONTRADO', retrn);
        setnewUser({
          ...newUser,
          nombre: retrn[0].nom_corto,
          ceco: retrn[0].ceco,
          cargo: retrn[0].cargo
        });
        setIsRut(true);
      }else{
        setIsRut(false);
      }
    }
    // toggleDrawer(false);
  }

  const HandleSetItem = async (e) => {
    setnewUser({
      ...newUser,
      [e.target.name]: e.target.value
    })
  }

  const SaveUser = async () => {
    const save = await SaveUserInyect({newUser});
    if(save){
      setisReload(!isReload);
      setDrawSate(false);
      setIsRut(false);
      setnewUser({
        rut: '',
        nombre: '',
        ceco:'',
        maquina:''
      })
    }
  }

  const SetIsComplete = async () => {
    if(newUser.rut !== '' && newUser.nombre !== '' && newUser.maquina !== ''){
      setIsComplete(false);
    } else {
      setIsComplete(true);
    }
  }

  useEffect(() => {
    SetIsComplete();
  }, [newUser])

  return (
    <>
      <Button variant='contained' size='small' onClick={toggleDrawer(true)}><PersonAddAlt1Icon /> <span className='px-1'> </span>Agregar Usuario</Button>
      <Drawer
        anchor={'bottom'}
        open={drawSate}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width:'auto'}}
          role="presentation"
        >
          <div className='container-fluid pt-3 pb-3'>
            <div className='row'>
              <div className='col-12'>
                <h3>Agregar Usuario</h3>
              </div>
              <div className='col-12 col-md-3'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="Rut"
                    value={newUser.rut}
                    variant="standard"
                    onChange={(e)=>formatRut(e)}
                    fullWidth
                    onKeyUp={verifiRut}/>
                </Box>
              </div>
              <div className='col-12 col-md-5'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField
                    type={'text'}
                    id="input-with-sx"
                    name='nombre'
                    fullWidth focused={isRut}
                    value={newUser.nombre}
                    onChange={ (e) => HandleSetItem(e) }
                    label="Nombre"
                    variant="standard"
                    // disabled={isRut}
                    />
                </Box>
              </div>
              <div className='col-12 pb-3 col-md-4'>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-standard-label" >Maquina Asignada</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name='maquina'
                    value={newUser.maquina}
                    onChange={ (e) => HandleSetItem(e) }
                    label="Maquina Asignada"
                  >
                    <MenuItem value='FOMACO FGM88 IQF 1'>FOMACO FGM88 IQF 1</MenuItem>
                    <MenuItem value='CFS 650 IQF 4'>CFS 650 IQF 4</MenuItem>
                    <MenuItem value='CFS 650 TRUTRO NORTE'>CFS 650 TRUTRO NORTE</MenuItem>
                    <MenuItem value='CFS 650 TRUTRO SUR'>CFS 650 TRUTRO SUR</MenuItem>
                    <MenuItem value='FOMACO FGM112'>FOMACO FGM112</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className='col-12 pt-3 text-right'>
                <Button variant='contained' color='error' size='small'
                  onClick={()=>{
                    setDrawSate(false)
                    setnewUser({
                      rut: '',
                      nombre: '',
                      ceco:'',
                      maquina:''
                    })
                  }}
                  >Cancelar <CancelOutlinedIcon style={{fontSize:'18',marginLeft: '5px',marginBottom:'5px'}} /></Button>
                  {/* espaciador */}
                <span className='px-2'></span>
                <Button
                  variant='contained'
                  size='small'
                  disabled={isComplete}
                  onClick={()=>SaveUser()}>Agregar Usuario <SaveIcon style={{fontSize:'18',marginLeft: '5px',marginBottom:'5px'}} />
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Drawer>
    </>
  )
}
