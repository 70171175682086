import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';

export const Chart_HC_line = ({titleChart, data}) => {

  if(data){

    const options = {
      chart: {
          type: 'spline',
          scrollablePlotArea: {
            minWidth: 600,
            scrollPositionX: 1
        }
      },
      title: {
          text: titleChart
      },
      // subtitle: {
      //     text: 'Source: ' +
      //         '<a href="https://en.wikipedia.org/wiki/List_of_cities_by_average_temperature" ' +
      //         'target="_blank">Wikipedia.com</a>'
      // },
      xAxis: {
          categories: data.categories,
          accessibility: {
              description: 'Months of the year'
          }
      },
      yAxis: {
          title: {
              text: '% Objetivo'
          },
        //   plotBands: [{ // Light air
        //     from: 25,
        //     to: 30,
        //     color: 'rgba(70, 196, 46, 0.2)',
        //     label: {
        //         text: 'Porcentaje Objetivo Esperado',
        //         style: {
        //             color: '#606060'
        //         }
        //     }
        // }],
          labels: {
              formatter: function () {
                  return this.value + '%';
              }
          }
      },
      tooltip: {
          crosshairs: true,
          shared: true
      },
      plotOptions: {
          spline: {
              marker: {
                  radius: 4,
                  lineColor: '#666666',
                  lineWidth: 1
              }
          }
      },
      series: data.series
  }  

    return (
      <>
        

        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      </>
    )
  } else {
    <h5>Cargando...</h5>
  }

  
}
