import {GetLogin,SetLogin} from '../types';

export default function (state, action) {
  const {payload, type} = action;
  // console.log('LoginReducer',payload)

  switch (type) {
    case GetLogin:
      return {
        ...state,
        payload
      }

    case SetLogin:
      return payload

    default:
      return state
  }
}