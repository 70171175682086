import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './cargaArchivo.css'
import ReplyIcon from '@mui/icons-material/Reply';
import DataContext from '../../context/Data/DataContext';
import AttachmentIcon from '@mui/icons-material/AttachFile';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import { Button } from '@mui/material';
import { url } from '../../link/Url.link';

export const CargaArchivo = () => {

  const navigate = useNavigate();
  const {state, getData, setData} = useContext(DataContext);
  const [File_, setFile_] = useState('');
  const [Archivo, setArchivo] = useState({})

  useEffect(() => {
    setData({
      linea: sessionStorage.getItem('linea'),
      equipo: sessionStorage.getItem('equipo'),
      usuario: sessionStorage.getItem('usuario')
    });
  }, [])
  

  const HandlePreloadFile = async (e) => {
    setArchivo(e.target.files[0]);
    setFile_(e.target.value.split('\\').pop());
    // console.log(e.target.files[0])
  }

  const HandleUploadFile = async () => {
    // console.log(Archivo)
    const formData = new FormData();
    formData.append('ARCHIVO',Archivo);
    const fetc = await fetch(url+'archivalo', {
      method: 'POST',
      headers: {
        'authorization': "marcuxo"
      },
      body: formData
    })

    const recuest = await fetc.json();
    if(recuest.success){
      // console.log()
      alert(`Se cargaron ${recuest.body.lineas} lineas de datos CORRECTAMENTE!!`)
      navigate('/');
    }else{
      alert('Error al cargar los datos favor intente nuevamente.')
    }
  }

  return (
    <>
      <div className='container-fluid mb-4'>
        <div className='row hdr-nav'>
          <div className='col-5 col-md-4 centro'>
            <span className="px-3 hoverClick" onClick={()=>navigate("/seleccion", { replace: true })}><ReplyIcon /> volver</span>
          </div>
          <div className='col-1 col-md-4 hidden-xs text-center'>
            <img src="img/logo_ariztia.png" className='cursor' alt='Logo Ariztia' height={'50px'} onClick={()=>navigate("/", { replace: true })}/>
          </div>
          <div className='col-7 col-md-4 text-right txt-user'>
            <span className="px-3 py-2 ">Usuario: {state.data.usuario}</span>
          </div>
        </div>
      </div>
      
      <div className='fondo container divmenu1 pb-5 px-3'>
        <div className='row text-center'>
          <div className='col text-left py-3'>
            <h5 className='txt-menu'>Cargar Archivo PAMCO</h5>
          </div>
          <a className='btn-float' 
            download={'pamco_ejemplo.xlsx'}
            href='/file/pamco_ejemplo.xlsx'
          >
            <p>Descargar ejemplo</p>
            <SimCardDownloadOutlinedIcon />
          </a>
          <div className='col-12'>
            <label htmlFor='file' id="secondfile" className='btn btn-sm btn-info'>{
              File_===''? 
              <>Seleccionar archivo <AttachmentIcon /></>
              :<><Inventory2OutlinedIcon /> {File_}</>
            }</label>
            <input
              type="file"
              name="file"
              id="file"
              className="form-control"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              required
              onChange={(e)=>HandlePreloadFile(e)}
            />
          </div>
          <div className='col-12'>
            <Button
              variant='contained'
              disabled={File_===''?true:false}
              onClick={()=>HandleUploadFile()}
            >
                Cargar Archivo <FilePresentOutlinedIcon />
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
