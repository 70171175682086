import React, { useReducer } from 'react'
import DataContext from './DataContext';
import DataReducer from './DataReducer';

const DataState = (props) => {
  const initiaState = {
    data: []
  }

  const [state, dispatch] = useReducer(DataReducer, initiaState);

  const getData = () => {
  }

  const setData = async (data) => {
    dispatch({
      type: 'SetData',
      payload: data
    })
  }

  return (
    <DataContext.Provider
      value={{
        state,
        setData,
        getData
      }}
    >
      { props.children }
    </DataContext.Provider>
  )
}

export default DataState