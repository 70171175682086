import React from 'react'
import { url } from '../../link/Url.link';

export const SaveManyProductos = ({Archivo}) => {
  const formData = new FormData();
  formData.append('ARCHIVO',Archivo)
  return new Promise(async function(resolve, reject) {
    try {
      const query = await fetch(url+'/savemanyproducto',{
        method: 'POST',
        headers: {
          'authorization': "marcuxo"
        },
        body: formData
      })

        const reqkkst = await query.json();
        
        if(reqkkst.success){
          resolve(reqkkst.success)
          alert('datos ingresado con exito');
        }else{
          resolve(reqkkst.error)
          alert('Upss!! ocurrio un error vuelve a intentarlo');
        }
      } catch (error) {
        resolve(null)
      }
  })
}
