import { Button, Drawer, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import { GetRutData } from '../../../components/GetRutData';
import { SaveUserInyect } from '../../../components/SaveUserInyect';
import LoginContext from '../../../context/Login/LoginContext';


// icons
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { SaveOneProducto } from '../../../components/productos/SaveOneProducto';

export const AddProduct = ({setisReload,isReload}) => {
  const {LoginState, SetLogin, CloseLogin, SetLoginDirect} = useContext(LoginContext);
  const [drawSate, setDrawSate] = useState(false);
  const [newProduct, setnewProduct] = useState({
    COD_PRODUCTO:'',
    MEAT:'',
    PRODUCTO:'',
    POR_INY_OPTMO:''
  });
  const [isComplete, setIsComplete] = useState(true)

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawSate(open);
  };

  const HandleSetItem = async (e) => {
    setnewProduct({
      ...newProduct,
      [e.target.name]: e.target.value.toUpperCase()
    })
  }

  const SaveProduct = async () => {
    // console.log(newProduct)
    const save = await SaveOneProducto(newProduct);
    if(save){
      setisReload(!isReload);
      setDrawSate(false);
      setnewProduct({
        COD_PRODUCTO:'',
        MEAT:'',
        PRODUCTO:'',
        POR_INY_OPTMO:''
      })
    }
  }

  const SetIsComplete_ = async () => {
    if(newProduct.COD_PRODUCTO !== '' && newProduct.MEAT !== '' && newProduct.POR_INY_OPTMO !== '' && newProduct.PRODUCTO !== ''){
      setIsComplete(false);
    } else {
      setIsComplete(true);
    }
  }

  useEffect(() => {
    SetIsComplete_();
  }, [newProduct])

  return (
    <>
      <Button variant='contained' size='small' onClick={toggleDrawer(true)}><PlaylistAddIcon /> <span className='px-1'> </span>Agregar Producto</Button>
      <Drawer
        anchor={'bottom'}
        open={drawSate}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width:'auto'}}
          role="presentation"
        >
          <div className='container-fluid pt-3 pb-3'>
            <div className='row'>
              <div className='col-12'>
                <h3>Agregar Producto</h3>
              </div>
              <div className='col-12 col-md-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx"
                    label="Codigo Producto"
                    variant="standard"
                    name='COD_PRODUCTO'
                    value={newProduct.COD_PRODUCTO}
                    onChange={ (e) => HandleSetItem(e) }
                    fullWidth/>
                </Box>
              </div>
              <div className='col-12 col-md-5'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField
                    type={'text'}
                    id="input-with-sx"
                    name='PRODUCTO'
                    fullWidth
                    value={newProduct.PRODUCTO}
                    onChange={ (e) => HandleSetItem(e) }
                    label="Nombre Producto"
                    variant="standard"
                    // disabled={isRut}
                    />
                </Box>
              </div>
              <div className='col-12 col-md-3'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField
                    type={'text'}
                    id="input-with-sx"
                    name='MEAT'
                    fullWidth
                    value={newProduct.MEAT}
                    onChange={ (e) => HandleSetItem(e) }
                    label="Tipo Producto"
                    variant="standard"
                    // disabled={isRut}
                    />
                </Box>
              </div>
              <div className='col-12 col-md-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField
                    type={'number'}
                    id="input-with-sx"
                    name='POR_INY_OPTMO'
                    fullWidth
                    value={newProduct.POR_INY_OPTMO}
                    onChange={ (e) => HandleSetItem(e) }
                    label="Porcentaje Objetivo"
                    variant="standard"
                    // disabled={isRut}
                    />
                </Box>
              </div>
              <div className='col-12 pt-3 text-right'>
                <Button variant='contained' color='error' size='small'
                  onClick={()=>{
                    setDrawSate(false)
                    // setnewUser({
                    //   rut: '',
                    //   nombre: '',
                    //   ceco:'',
                    //   maquina:''
                    // })
                  }}
                  >Cancelar <CancelOutlinedIcon style={{fontSize:'18',marginLeft: '5px',marginBottom:'5px'}} /></Button>
                  {/* espaciador */}
                <span className='px-2'></span>
                <Button
                  variant='contained'
                  size='small'
                  disabled={isComplete}
                  onClick={()=>SaveProduct()}
                >Agregar Producto <SaveIcon style={{fontSize:'18',marginLeft: '5px',marginBottom:'5px'}} />
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Drawer>
    </>
  )
}
