import React, { useReducer } from 'react'
import { url_login } from '../../link/Url.link';
import LoginContext from './LoginContext';
import LoginReducer from './LoginReducer';

const LoginState = (props) => {
  const initialLoginState = {
    user: 'no-user',
    lastLogin: 'no-login',
    data: []
  }

  const [state, dispatch] = useReducer(LoginReducer, initialLoginState);

  /**
   * verifica en base de datos el usuario y la contraseña para login
   * @param {string} user 
   * @param {Date} lstLogin 
   */
  const SetLogin = (user, lstLogin) => {

    return new Promise(async function(resolve, reject) {
      // console.log('usuarioclave', user)
      try {
        const query = await fetch(url_login,{
          method: 'POST',
          headers: {
            'authorization': "marcuxo",
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            user:user.user,
            clave:user.password
          })
        })
  
          const reqkkst = await query.json();
          // console.log('loginState',reqkkst)
          if(reqkkst.data.success){
            resolve(reqkkst.data.success)
            sessionStorage.setItem('user',reqkkst.data.nombre);
            sessionStorage.setItem('token',reqkkst.data.token);
            
            dispatch({
              type: 'SetLogin',
              payload: {
                user: reqkkst.data.nombre,
                lastLogin: new Date(lstLogin)
              }
            })

          }else{
            resolve(reqkkst.data.success)
          }
        } catch (error) {
          resolve(false)
        }
      
  
    })
    
    // console.log('LoginState', user,lstLogin)
    // dispatch({
    //   type: 'SetLogin',
    //   payload: {
    //     user: user.user,
    //     lastLogin: new Date(lstLogin)
    //   }
    // })
  }

  const SetLoginDirect = async () => {
    return new Promise(async function(resolve, reject) {
      const user = sessionStorage.getItem('user');
      const token = sessionStorage.getItem('token');
      if(user){
        resolve(true)
        dispatch({
          type: 'SetLogin',
          payload: {
            user: user,
            lastLogin: new Date()
          }
        })
      }else{
        resolve(false)
      }
    })
  }

  const CloseLogin = () => {
    sessionStorage.clear();
    return (dispatch({
      type: 'SetLogin',
      payload: {
        user: 'no-user',
        lastLogin: null
      }
    }))
  }

  return(
    <LoginContext.Provider
      value={{
        LoginState:state,
        SetLogin,
        CloseLogin,
        SetLoginDirect
      }}
    >
      { props.children}
    </LoginContext.Provider>
  )

}

export default LoginState