import React from 'react'
import { url } from '../link/Url.link';

export const DownloadFileData = ({value}) => {
  console.log('first', value)
  return new Promise(async function(resolve, reject) {
    try {
      const query = await fetch(url+'/downloadfile',{
        method: 'POST',
        headers: {
          'authorization': "marcuxo",
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          value
        })
      })

        const reqkkst = await query.json();
        
        if(reqkkst.success){
          resolve(reqkkst)
          alert('La operacion fue realizada con exito!!');
        }else{
          resolve(reqkkst.error)
          alert('Upss!! ocurrio un error vuelve a intentarlo');
        }
      } catch (error) {
        resolve(null)
      }
  })
}
