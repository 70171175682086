
let url_login = "https://apimedidores.apidev.info/signin/"

let url = "https://apimedidores.apidev.info/inyeccion/"
let url_server = "https://apimedidores.apidev.info/"

// let url = "http://localhost:4088/inyeccion/"
// let url_server = "http://localhost:4088/"

// let direcUrl = '10.30.10.230:4088'

//aplicaion
// let direcUrl = 'localhost:4088'
// let url_login = "http://"+direcUrl+"/signin/"
// let url = "http://"+direcUrl+"/inyeccion/"
// let url = "https://apimedidores.apidev.info/inyeccion/"
// let url_login = "https://apimedidores.apidev.info/signin/"


module.exports = {
  url, url_login, url_server
}