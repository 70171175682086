import React, { useEffect, useState } from 'react'
import { Button, IconButton, TextField } from '@mui/material';

import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { GetProducto } from '../../components/productos/GetProducto';
import { EditProduct } from './crudUser/EditProduct.comp';
import { DeleteProducto } from '../../components/productos/DeleteProducto';
import { AddProduct } from './crudUser/AddProduct.comp';
import { AddFileProduct } from './crudUser/AddFileProduct.comp';

export const Productos = () => {
  const [listProdcto, setListProdcto] = useState([]);
  const [listProdcto2, setListProdcto2] = useState([]);
  const [isReload, setisReload] = useState(false);

  const getProductos = async () => {
    const listP = await GetProducto()
   if(listP.success){
    setListProdcto(listP.body)
    setListProdcto2(listP.body)
   }
  }

  const DeleteItem = async (id, nombre) => {
    let confirmar = window.confirm(`esta seguro de eliminar el producto ${nombre}`);
    if(confirmar){
      const isdelete = await DeleteProducto({id});
      if(isdelete.success)setisReload(!isReload)
    }
    return
  }

  useEffect(() => {
    getProductos();
  }, [isReload])

  const findProduct = async (e) => {
    let filtro = await listProdcto2.filter(val=>val.COD_PRODUCTO.includes(e.target.value) || val.PRODUCTO.toLowerCase().includes(e.target.value.toLowerCase().trim()))
    // console.log('buscar', e.target.value);
    setListProdcto(filtro)

  }
  
  return (
    <>
      <div className='container fondo'>
          <div className='row'>
            <div className='col'>
              <h1><BallotOutlinedIcon style={{fontSize: '40', marginBottom: '10', marginRight: '10'}} />Productos</h1>
            </div>
          </div>
        </div>

        <div className='container mt-3 fondo'>
        <div className='row'>
          <div className='col-12 text-center pt-3'>
            <div className='row'>
              <div className='col-12 col-md-4 my-2'>
                <AddProduct isReload={isReload} setisReload={setisReload} />
              </div>
              <div className='col-12 col-md-4 my-2'>
                <AddFileProduct isReload={isReload} setisReload={setisReload} />
              </div>
              <div className='col-12 col-md-4 my-1'>
                <TextField
                  id="outlined-number"
                  label="Buscar Producto"
                  type="search"
                  size='small'
                  onChange={(e)=>findProduct(e)}
                  
                />
              </div>
            </div>
          </div>
          
          <div className='col-12 pt-3'>
            <table className="table table-sm table-hover ">
              <thead>
                <tr>
                  <th scope="col">Codigo</th>
                  <th scope="col">Producto</th>
                  <th scope="col">Tipo</th>
                  <th scope="col">%</th>
                  <th scope="col">Acciones</th>
                </tr>
              </thead>
              <tbody>

                {
                  listProdcto.map(list=>
                    <tr key={list._id}>
                      <td style={{fontSize:'8'}}>{list.COD_PRODUCTO}</td>
                      <td>{list.PRODUCTO}</td>
                      <td>{list.MEAT}</td>
                      <td>{list.POR_INY_OPTMO}%</td>
                      <td>
                        {/* <span className='bg-danger py-2 rounded-circle mr-2'> */}
                        <span className='bg-danger py-2 rounded-circle mr-2' onClick={()=>DeleteItem(list._id, list.PRODUCTO)}>
                          <IconButton >
                            <DeleteForeverRoundedIcon style={{color: '#fff', paddingBottom: '5'}}/>
                          </IconButton>
                        </span>
                        <span className='bg-warning py-2 rounded-circle mr-2'>
                          <EditProduct isReload={isReload} setisReload={setisReload} list={list} />
                        </span>
                      </td>
                    </tr>
                  )
                }

              </tbody>
            </table>

          </div>
        </div>
      </div>
    </>
  )
}
