import React, { useEffect, useState } from 'react'
import './ChartStile.css'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { GetUsers } from './GetUsers.FilterChart';
import { GetMachines } from './GetMachines.FilterChart';
import { GetProduct } from './GetProduct.filterChart';
import { GetChartFilter } from './GetChartFilter';
import { Chart_HighChart } from '../../../view/chart/Chart_HighChart';
import { Chart_HC_line } from '../../../view/chart/Chart_HC_line';

const now = new Date();
const yesterdayBegin = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
const todayEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);

export const ChartAreaFilter = () => {
  const [value, onChange] = useState([yesterdayBegin, todayEnd]);

  const [get_User, setgetUser] = useState([]);
  const [get_Machine, setgetMachine] = useState([]);
  const [get_Producto, setgetProducto] = useState([]);

  const [filterData,setFilterData] = useState({
    MAQUINA:'',
    PRODUCTO:'',
    OPERARIO:''
  });

  const [DataChart, setDataChart] = useState(null)

  const LoadData_User = async () => {
    const data_ = await GetUsers()
    await setgetUser(data_)
  }
  const LoadData_Machine = async () => {
    const data_ = await GetMachines()
    await setgetMachine(data_)
  }
  const LoadData_Producto = async () => {
    const data_ = await GetProduct()
    await setgetProducto(data_)
  }

  useEffect(() => {
    LoadData_Machine()
    LoadData_Producto()
    LoadData_User()
  }, [])

  

  const HandleOnChange = async (e) => {
    // console.log(e.target.value, e.target.name)
    setFilterData({...filterData, [e.target.name]:e.target.value})
  }

  const GetDataToFIlterChart = async () => {
    
    let filterChartData = await GetChartFilter({filterData,FECHARANGO:value})
    await setDataChart(filterChartData)
    // console.log(filterChartData)
  }

  useEffect(() => {
    GetDataToFIlterChart()
  }, [filterData, value])
  
  

  return (
    <>
      <div className='container-fluid py-2'>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-3'>
            <DateRangePicker
              calendarAriaLabel="Toggle calendar"
              clearAriaLabel="Clear value"
              dayAriaLabel="Day"
              monthAriaLabel="Month"
              nativeInputAriaLabel="Date"
              yearAriaLabel="Year"
              locale='ES-cl'
              value={value}
              onChange={onChange}
            />
          </div>
          <div className='col-12 col-md-6 col-lg-3'>
            <FormControl sx={{ m: 1, minWidth: 120 }}fullWidth>
              <InputLabel id="demo-controlled-open-select-label" size='small'>Maquina</InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                label="Maquina"
                size='small'
                name='MAQUINA'
                value={filterData.MAQUINA}
                onChange={HandleOnChange}
              >
                {
                  get_Machine.map((mqn, key)=><MenuItem key={key} style={{fontSize: '12px'}} value={mqn._id}>{mqn._id}</MenuItem>)
                }
                
              </Select>
            </FormControl>
          </div>
          <div className='col-12 col-md-6 col-lg-3'>
            <FormControl sx={{ m: 1, minWidth: 120 }}fullWidth>
              <InputLabel id="demo-controlled-open-select-label" size='small'>Operario</InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                label="Operario"
                size='small'
                name='OPERARIO'
                value={filterData.OPERARIO}
                onChange={HandleOnChange}
              >
                {get_User.map((opr, key)=><MenuItem key={key} style={{fontSize: '12px'}} value={opr._id}>{opr._id}</MenuItem>)}
              </Select>
            </FormControl>
          
          </div>
          <div className='col-12 col-md-6 col-lg-3'>
            <FormControl sx={{ m: 1, minWidth: 120 }}fullWidth>
              <InputLabel id="demo-controlled-open-select-label" size='small'>Producto</InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                label="Producto"
                size='small'
                name='PRODUCTO'
                value={filterData.PRODUCTO}
                onChange={HandleOnChange}
              >
                {get_Producto.map((pdt, key)=><MenuItem key={key} 
                style={{fontSize: '12px'}} value={pdt._id}>{pdt._id}</MenuItem>)}
              </Select>
            </FormControl>
          </div>
          

          <div className='col-12'>
            <Chart_HighChart
              data={DataChart}
              titleChart={'Filtrado'}
            />
          </div>
        </div>
      </div>
    </>
  )
}
