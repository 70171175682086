import { url } from '../link/Url.link'

export const SaveDataInyect = ({AllData}) => {
  // console.log(AllData)
  return new Promise(async function(resolve, reject) {
    try {
      const query = await fetch(url+'/savedata',{
        method: 'POST',
        headers: {
          'authorization': "marcuxo",
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: AllData,
        })
      })

        const reqkkst = await query.json();
        
        if(reqkkst.success){
          resolve(reqkkst.success)
          alert('datos ingresado con exito');
        }else{
          resolve(reqkkst.error)
          alert('Upss!! ocurrio un error vuelve a intentarlo');
        }
      } catch (error) {
        resolve(null)
      }
    

  })
}
