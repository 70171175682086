import React from 'react'
import {url} from '../link/Url.link'
export const GetOfs = async ({theData}) => {
  // console.log('Linea=>>', theData.linea)
  return new Promise(async function(resolve, reject) {
    try {
      const query = await fetch(url+'/getofs',{
        method: 'POST',
        headers: {
          'authorization': "marcuxo",
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          maquina: theData.linea,
        })
      })

        const reqkkst = await query.json();
        // console.log(reqkkst.body)
        if(reqkkst.success){
          resolve(reqkkst.body)
        }else{
          resolve(null)
        }
      } catch (error) {
        resolve(null)
      }
    

  })

}
