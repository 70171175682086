import { url } from "../../link/Url.link";

export const GetProducto = () => {
  return new Promise(async function(resolve, reject) {
    try {
      const query = await fetch(url+'/getproductos',{
        method: 'POST',
        headers: {
          'authorization': "marcuxo",
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })

        const reqkkst = await query.json();
        // console.log(reqkkst);
        if(reqkkst.success){
          resolve(reqkkst)
        }else{
          resolve(reqkkst.error)
          alert('Upss!! ocurrio un error vuelve a intentarlo');
        }
      } catch (error) {
        resolve(null)
      }
  })
}
