import React from 'react'
import { CrudUser } from './CrudUser.view';
import { MainDashBoard } from './MainDashBoard.view';
import { Productos } from './Productos.view';

const ViewPage = ({ViewNow}) => {
  // console.log('ViewPage',ViewNow)
  switch (ViewNow) {
    case 'crud-user':
      return(
        <CrudUser />
      )

    case 'productos':
      return(
        <Productos />
      )
  
    default:
      return (
        <MainDashBoard />
      )
  }
}

export default ViewPage