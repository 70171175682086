import { Box, Button, Drawer, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useState } from 'react'

//icons
import SaveIcon from '@mui/icons-material/Save';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import { EditProducto } from '../../../components/productos/EditProducto';

export const EditProduct = ({list, isReload, setisReload}) => {
  // console.log(oneUser)
  const [drawSate, setDrawSate] = useState(false);
  const [editUser, setEditUser] = useState({
    COD_PRODUCTO:list.COD_PRODUCTO,
    PRODUCTO:list.PRODUCTO,
    MEAT:list.MEAT,
    POR_INY_OPTMO:list.POR_INY_OPTMO,
    ID: list._id
  })

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawSate(open);
  };

  const HandleSetItem = async (e) => {
    setEditUser({
      ...editUser,
      [e.target.name]: e.target.value.toUpperCase()
    })
  }

  const sendToEdit = async () => {
    const editado = await EditProducto(editUser)
    if(editado){
      setDrawSate(false);
      setisReload(!isReload);
    }
  }

  return (
    <>
      {/* <Button variant='contained' size='small' onClick={toggleDrawer(true)}><PersonAddAlt1Icon /> <span className='px-1'> </span>Agreagar Usuario</Button> */}
      <IconButton color='error' onClick={toggleDrawer(true)}>
        <BorderColorRoundedIcon style={{color: '#fff', paddingBottom: '5'}} fontSize='small'/>
      </IconButton>
      <Drawer
        anchor={'bottom'}
        open={drawSate}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width:'auto'}}
          role="presentation"
        >
          <div className='container-fluid pt-3 pb-3'>
            <div className='row'>
              <div className='col-12'>
                <h3>Editar Producto</h3>
              </div>
              <div className='col-12 col-md-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="Codigo Producto"
                    value={editUser.COD_PRODUCTO}
                    variant="standard"
                    name='COD_PRODUCTO'
                    onChange={ (e) => HandleSetItem(e) }
                    focused
                    disabled
                    fullWidth/>
                </Box>
              </div>
              <div className='col-12 col-md-5'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField
                    type={'text'}
                    id="input-with-sx"
                    name='PRODUCTO'
                    fullWidth
                    value={editUser.PRODUCTO}
                    onChange={ (e) => HandleSetItem(e) }
                    label="Nombre Producto"
                    variant="standard"
                    // disabled={isRut}
                    />
                </Box>
              </div>
              <div className='col-12 col-md-3'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField
                    type={'text'}
                    id="input-with-sx"
                    name='MEAT'
                    fullWidth
                    value={editUser.MEAT}
                    onChange={ (e) => HandleSetItem(e) }
                    label="Tipo Producto"
                    variant="standard"
                    // disabled={isRut}
                    />
                </Box>
              </div>
              <div className='col-12 col-md-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField
                    type={'number'}
                    id="input-with-sx"
                    name='POR_INY_OPTMO'
                    fullWidth
                    value={editUser.POR_INY_OPTMO}
                    onChange={ (e) => HandleSetItem(e) }
                    label="Porcentaje Inyeccion"
                    variant="standard"
                    // disabled={isRut}
                    />
                </Box>
              </div>
              <div className='col-12 pt-3 text-right'>
                <Button variant='contained' color='error' size='small'
                  onClick={()=>{
                    setDrawSate(false)
                    // setnewUser({
                    //   rut: '',
                    //   nombre: '',
                    //   ceco:'',
                    //   maquina:''
                    // })
                  }}
                  >Cancelar <CancelOutlinedIcon style={{fontSize:'18',marginLeft: '5px',marginBottom:'5px'}} /></Button>
                  {/* espaciador */}
                <span className='px-2'></span>
                <Button
                  variant='contained'
                  size='small'
                  // disabled={isComplete}
                  onClick={()=>sendToEdit()}
                >Editar Producto <SaveIcon style={{fontSize:'18',marginLeft: '5px',marginBottom:'5px'}} />
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Drawer>
    </>
  )
}
