import React from 'react'
import { url } from '../../link/Url.link';

export const SaveOneProducto = (newProduct) => {
  return new Promise(async function(resolve, reject) {
    try {
      const query = await fetch(url+'/saveoneproducto',{
        method: 'POST',
        headers: {
          'authorization': "marcuxo",
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          newProduct
        })
      })

        const reqkkst = await query.json();
        
        if(reqkkst.success){
          resolve(reqkkst.success)
          alert('datos ingresados con exito');
        }else{
          resolve(reqkkst.error)
          alert('Upss!! ocurrio un error vuelve a intentarlo');
        }
      } catch (error) {
        resolve(null)
      }
  })
}
