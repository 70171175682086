import { Button, Drawer, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import { GetRutData } from '../../../components/GetRutData';
import { SaveUserInyect } from '../../../components/SaveUserInyect';
import LoginContext from '../../../context/Login/LoginContext';

import AttachmentIcon from '@mui/icons-material/AttachFile';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';


// icons
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { SaveOneProducto } from '../../../components/productos/SaveOneProducto';
import { SaveManyProductos } from '../../../components/productos/SaveManyProductos';
import { url } from '../../../link/Url.link';

export const AddFileProduct = ({setisReload,isReload}) => {
  const {LoginState, SetLogin, CloseLogin, SetLoginDirect} = useContext(LoginContext);
  const [drawSate, setDrawSate] = useState(false);
  const [isComplete, setIsComplete] = useState(true)
  const [File_, setFile_] = useState('');
  const [Archivo, setArchivo] = useState({})

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawSate(open);
  };

  const HandlePreloadFile = async (e) => {
    setArchivo(e.target.files[0]);
    setFile_(e.target.value.split('\\').pop());
    // console.log(e.target.files[0])
  }

  const SaveFileProduct_ = async () => {
    // console.log('newProduct')
    const save = await SaveManyProductos({Archivo});
    if(save){
      setisReload(!isReload);
      setDrawSate(false);
      setArchivo({});
      setFile_('');
    }
  }

  const HandleDownloadListProducts = async () => {
    window.location.href = url+`downloadproducts_get`
  }


  return (
    <>
      <Button variant='contained' size='small' onClick={toggleDrawer(true)}>
        <AttachFileIcon /> <span className='px-1'> </span>Agregar Archivo</Button>
      <Drawer
        anchor={'bottom'}
        open={drawSate}
        onClose={()=>{
          setArchivo({});
          setFile_('');
          setDrawSate(false)
        }}
      >
        <Box
          sx={{ width:'auto'}}
          role="presentation"
        >
          <div className='container-fluid pt-3 pb-3'>
            <div className='row'>
              <div className='col-12'>
                <h3>Agregar Archivo</h3>
              </div>
              
              
               
      <div className='col-12'>
        <div className='row'>
          <div className='col-12 col-md-2'></div>
          <div className='col-12 col-md-8'>
            <div className='row text-center'>
            
              <div className='col'>
                <label htmlFor='file' id="secondfile" className='btn btn-sm btn-info'>{
                  File_===''? 
                  <>Seleccionar archivo <AttachmentIcon /></>
                  :<><Inventory2OutlinedIcon /> {File_}</>
                }</label>
                <input
                  type="file"
                  name="file"
                  id="file"
                  className="form-control"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  required
                  onChange={(e)=>HandlePreloadFile(e)}
                />
              </div>
              <div className='col-12'>
                <Button
                  variant='contained'
                  disabled={File_===''?true:false}
                  onClick={()=>SaveFileProduct_()}
                >
                    Cargar Archivo <FilePresentOutlinedIcon />
                </Button>
              </div>
              
          </div>
          </div>
          
          <div className='col-12 text-center col-md-2 pt-3'>
            <button className='btn btn-info' 
              onClick={()=>HandleDownloadListProducts()}
            >
              <p className='m-0'><small>Descargar Lista de Productos</small></p>
              {/* <SimCardDownloadOutlinedIcon /> */}
            </button>
          </div>
        </div>
      </div>
             
              <div className='col-12 pt-3 text-right'>
                <Button variant='contained' color='error' size='small'
                  onClick={()=>{
                    setDrawSate(false)
                    setArchivo({})
                    setFile_('')
                  }}
                  >Cancelar <CancelOutlinedIcon style={{fontSize:'18',marginLeft: '5px',marginBottom:'5px'}} /></Button>
                  {/* espaciador */}
                <span className='px-2'></span>
                {/* <Button
                  variant='contained'
                  size='small'
                  // disabled={isComplete}
                  onClick={()=>SaveProduct()}
                >Agregar <SaveIcon style={{fontSize:'18',marginLeft: '5px',marginBottom:'5px'}} />
                </Button> */}
              </div>
            </div>
          </div>
        </Box>
      </Drawer>
    </>
  )
}
