import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';

export const Chart_HighChart = ({titleChart, data}) => {
  if(data){
    // console.log(data)
    const options = {
      chart: {
          type: 'column',
      },
      title: {
          text: titleChart
      },
      // subtitle: {
      //     text: 'Source: ' +
      //         '<a href="https://www.ssb.no/en/statbank/table/08940/" ' +
      //         'target="_blank">SSB</a>'
      // },
      xAxis: {
          categories:data.categories,
          crosshair: true
      },
      yAxis: {
          title: {
              useHTML: true,
              text: 'Cantidad'
          }
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
        series:{
          cursor: 'pointer',
          events:{
            click: function(e) {
              // console.log(
              //   e.point.category,
              //   e.path[0].point.series.name
              // ) 
            }
          }
        },
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
      },
      series: data.series
    }
     return (
      <>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      /></>
    )
  } else {
    return 
  }
  

 
}
