import { Box, Button, Drawer, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useState } from 'react'

//icons
import SaveIcon from '@mui/icons-material/Save';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import { EditUserInyect } from '../../../components/EditUserInyect';

export const EditUser = ({oneUser, isReload, setisReload}) => {
  // console.log(oneUser)
  const [drawSate, setDrawSate] = useState(false);
  const [editUser, setEditUser] = useState({
    RUT:oneUser.RUT,
    NOMBRE:oneUser.NOMBRE,
    MAQUINA:oneUser.MAQUINA,
    ID:oneUser._id,
    BOSS_LINE:oneUser.BOSS_LINE
  })

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawSate(open);
  };

  const HandleSetItem = async (e) => {
    setEditUser({
      ...editUser,
      [e.target.name]: e.target.value
    })
  }

  const sendToEdit = async () => {
    const editado = await EditUserInyect({editUser})
    if(editado){
      setDrawSate(false);
      setisReload(!isReload);
    }
  }

  return (
    <>
      {/* <Button variant='contained' size='small' onClick={toggleDrawer(true)}><PersonAddAlt1Icon /> <span className='px-1'> </span>Agreagar Usuario</Button> */}
      <IconButton color='error' onClick={toggleDrawer(true)}>
        <BorderColorRoundedIcon style={{color: '#fff', paddingBottom: '5'}} fontSize='small'/>
      </IconButton>
      <Drawer
        anchor={'bottom'}
        open={drawSate}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width:'auto'}}
          role="presentation"
        >
          <div className='container-fluid pt-3 pb-3'>
            <div className='row'>
              <div className='col-12'>
                <h3>Editar Usuario</h3>
              </div>
              <div className='col-12 col-md-3'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="Rut"
                    value={editUser.RUT}
                    variant="standard"
                    onChange={ (e) => HandleSetItem(e) }
                    focused
                    disabled
                    fullWidth/>
                </Box>
              </div>
              <div className='col-12 col-md-5'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField
                    type={'text'}
                    id="input-with-sx"
                    name='NOMBRE'
                    fullWidth
                    value={editUser.NOMBRE}
                    onChange={ (e) => HandleSetItem(e) }
                    label="Nombre"
                    variant="standard"
                    // disabled={isRut}
                    />
                </Box>
              </div>
              <div className='col-12 pb-3 col-md-4'>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-standard-label" >Maquina</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name='MAQUINA'
                    value={editUser.MAQUINA}
                    onChange={ (e) => HandleSetItem(e) }
                    label="Maquina"
                  >
                    <MenuItem value='CFS 450 IQF 1'>CFS 450 IQF 1</MenuItem>
                    <MenuItem value='CFS 650 IQF 4'>CFS 650 IQF 4</MenuItem>
                    <MenuItem value='CFS 650 TRUTRO NORTE'>CFS 650 TRUTRO NORTE</MenuItem>
                    <MenuItem value='CFS 650 TRUTRO SUR'>CFS 650 TRUTRO SUR</MenuItem>
                    <MenuItem value='METALQUIMIA'>METALQUIMIA</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className='col-12 pt-3 text-right'>
                <Button variant='contained' color='error' size='small'
                  onClick={()=>{
                    setDrawSate(false)
                    // setnewUser({
                    //   rut: '',
                    //   nombre: '',
                    //   ceco:'',
                    //   maquina:''
                    // })
                  }}
                  >Cancelar <CancelOutlinedIcon style={{fontSize:'18',marginLeft: '5px',marginBottom:'5px'}} /></Button>
                  {/* espaciador */}
                <span className='px-2'></span>
                <Button
                  variant='contained'
                  size='small'
                  // disabled={isComplete}
                  onClick={()=>sendToEdit()}
                >Editar <SaveIcon style={{fontSize:'18',marginLeft: '5px',marginBottom:'5px'}} />
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Drawer>
    </>
  )
}
