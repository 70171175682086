import React, { useContext, useEffect, useState } from 'react'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import { AddUser } from './crudUser/AddUser.comp';
import { GetUsersInyect } from '../../components/GetUsersInyect';
import LoginContext from '../../context/Login/LoginContext';
import { Button, IconButton } from '@mui/material';
import { EditUser } from './crudUser/EditUser.comp';
import { DeleteUserInyect } from '../../components/DeleteUserInyect';

export const CrudUser = () => {
  const [UserList, setUserList] = useState([]);
  const {LoginState} = useContext(LoginContext);
  const [isReload, setisReload] = useState(false)
  
  const HandleHetUsers = async () => {
    const list = await GetUsersInyect();
    setUserList(list.body);
  }

  const DeleteItem = async (id, nombre) => {
    let confirmar = window.confirm(`esta seguro de eliminar al usuario ${nombre}`);
    if(confirmar){
      const isdelete = await DeleteUserInyect({id});
      if(isdelete.success)setisReload(!isReload)
    }
    return
  }
  
  useEffect(() => {
    HandleHetUsers()
  }, [isReload])
  
  return (
    <>
      <div className='container fondo'>
        <div className='row'>
          <div className='col'>
            <h1><AccountCircleOutlined style={{fontSize: '40', marginBottom: '10'}} />Usuarios</h1>
          </div>
        </div>
      </div>
      <div className='container mt-3 fondo'>
        <div className='row'>
          <div className='col-12 text-center pt-3'><AddUser isReload={isReload} setisReload={setisReload} /></div>
          <div className='col-12 pt-3'>
            <table className="table table-sm table-hover ">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Maquina</th>
                  <th scope="col">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {
                  UserList.map(list=>
                    <tr key={list._id}>
                      <th className='autocenter'><AccountCircleOutlinedIcon /></th>
                      <td>{list.NOMBRE}</td>
                      <td>{list.MAQUINA}</td>
                      <td>
                        <span className='bg-danger py-2 rounded-circle mr-2' onClick={()=>DeleteItem(list._id, list.NOMBRE)}>
                          <IconButton >
                            <DeleteForeverRoundedIcon style={{color: '#fff', paddingBottom: '5'}}/>
                          </IconButton>
                        </span>
                        <span className='bg-info py-2 rounded-circle'>
                          <EditUser
                            isReload={isReload}
                            setisReload={setisReload}
                            oneUser={list} />
                        </span>
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>

          </div>
        </div>
      </div>

    </>
  )
}
