import { url } from "../link/Url.link";

export const SaveUserInyect = ({newUser}) => {
  // console.log('////////', newUser)
  return new Promise(async function(resolve, reject) {
    try {
      const query = await fetch(url+'/saveuserinyect',{
        method: 'POST',
        headers: {
          'authorization': "marcuxo",
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: newUser,
        })
      })

        const reqkkst = await query.json();
        
        if(reqkkst.success){
          resolve(reqkkst.success)
          alert('datos ingresado con exito');
        }else{
          resolve(reqkkst.error)
          alert('Upss!! ocurrio un error vuelve a intentarlo');
        }
      } catch (error) {
        resolve(null)
      }
  })
}
