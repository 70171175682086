import React, { useContext, useEffect, useRef, useState } from 'react'
import ReplyIcon from '@mui/icons-material/Reply';
import LinkedCameraIcon from '@mui/icons-material/LinkedCamera';
import { useNavigate } from 'react-router-dom';
import DataContext from '../../context/Data/DataContext';
import { Button, TextField } from '@mui/material';
import './ingreso.css'
import exportAsImage from '../../components/createIMG/exportAsImage';
import { SaveDataInyect } from '../../components/SaveDataInyect';

export const Ingreso = () => {
  const exposrtRef = useRef();
  const {state, setData, getData} = useContext(DataContext)
  const navigate = useNavigate()
  // console.log('ingreso state', state);
  const [IsComplete, setIsComplete] = useState(true)

  const [Hora_, setHora_] = useState('00:00');
  const [fecha_, setfecha_] = useState('2077-12-24');
  const [AllData, setAllData] = useState({
    user: state.data.usuario,
    hora:Hora_,
    fecha:fecha_,
    maquina:state.data.equipo,
    n_of:state.data.of,
    kgin:'',
    kgout:'',
    porin:0,
    press:'',
    velcint:'',
    gxm:'',
    temp:'',
    supervisor: ''
  })

  const setHoraFechaInput = () => {
    setInterval(() => {
    let a = new Date();
    let hh = a.getHours()<10?'0'+a.getHours():a.getHours();
    let min = a.getMinutes()<10?'0'+a.getMinutes():a.getMinutes();
    let seg = a.getSeconds()<10?'0'+a.getSeconds():a.getSeconds();
    let hour = `${hh}:${min}`;
    let dd = a.getDate()<10?'0'+a.getDate():a.getDate();
    let mm = (a.getMonth()+1)<10?'0'+(a.getMonth()+1):(a.getMonth()+1);
    let yyyy = a.getFullYear()
      setHora_(hour)
      setfecha_(`${yyyy}-${mm}-${dd}`)
    }, 3000);
  }

  const CalculatePocentOfInyection = () => {
    if(AllData.kgin !== '' && AllData.kgout !== ''){
      let a = parseFloat(AllData.kgout)  - parseFloat(AllData.kgin);
      let b = (a*100)/parseFloat(AllData.kgin)
      let c = b.toFixed(2)
      
      setAllData({...AllData,['porin']:c})
    }
  }

  useEffect(() => {
    CalculatePocentOfInyection()
  }, [AllData.kgin, AllData.kgout])

  useEffect(() => {
    setHoraFechaInput();
    setData({
      linea: sessionStorage.getItem('linea'),
      equipo: sessionStorage.getItem('equipo'),
      usuario: sessionStorage.getItem('usuario')
    });
  }, [])

  useEffect(() => {
    setAllData({
      ...AllData,
      hora:Hora_,
      fecha:fecha_,
    })
    return () => {
    }
  }, [Hora_, fecha_])

  //verifi IsComplete
  useEffect(() => {
    const keys = Object.keys(AllData)
    let count = 0;
    keys.forEach(ky => {
      if(AllData[ky]==='')count=1
    });
    count?setIsComplete(true):setIsComplete(false)
  }, [AllData])
  

  const HamdleSaveDataInyeccion = async () => {
    // console.log(AllData)
    const resp_ = await SaveDataInyect({AllData});
    if(resp_)navigate('/seleccion')
  }

  return (
    <>
      <div className='container-fluid mb-4'>
        <div className='row hdr-nav'>
          <div className='col-5 col-md-4 centro'>
            <span className="px-3 hoverClick" onClick={()=>navigate("/seleccion", { replace: true })}><ReplyIcon /> volver</span>
          </div>
          <div className='col-1 col-md-4 hidden-xs text-center'>
            <img src="img/logo_ariztia.png" className='cursor' alt='Logo Ariztia' height={'50px'} />
          </div>
          <div className='col-7 col-md-4 text-right txt-user'>
            <span className="px-3 py-2 ">Usuario: {AllData.user}</span>
          </div>
        </div>
      </div>

      <div className='container fondo divmenu1 mb-5'>
        <div className='row  p-2 py-5' ref={exposrtRef}>
          <div className='col-12'>
            <div className='row text-center'>
              <div className='col-12 col-md-4 col-lg-3 p-2'>
                <TextField id="outlined-basic" focused fullWidth disabled name='a1' type={'text'} value={AllData.maquina} size='small' label="Maquina" variant="outlined" />
              </div>
              <div className='col-12 col-md-4 col-lg-3 p-2'>
                <TextField id="outlined-basic" focused fullWidth disabled name='a2' type={'text'} value={AllData.n_of} size='small' label="Numero OF" variant="outlined" />
              </div>
              <div className='col-12 col-md-4 col-lg-3 p-2'>
                <TextField id="outlined-basic" focused disabled type={'date'} name='a3' value={AllData.fecha} fullWidth size='small' label="Fecha" variant="outlined" />
              </div>
              <div className='col-12 col-md-4 col-lg-3 p-2'>
                <TextField id="outlined-basic" focused disabled type={'time'} name='a4' value={AllData.hora} fullWidth size='small' label="Hora" variant="outlined" />
              </div>
            </div>
          </div>
          <div className='col-12 fondo divmenu-inj my-3'>
            {/* ref={exposrtRef} */}
            <div className='row text-center' >
              <div className='col-12 col-md-6 p-2 centro'>
                <div className='row'>
                  <div className='col-12 '>
                    <div className='col-12'><p className='lead font-weight-bold'>PESAJE PRODUCTO</p></div>
                    <div className='col-12 py-2'>
                      <TextField id="outlined-basic" name='kgin' type={'number'} value={AllData.kgin} fullWidth onChange={(e)=>setAllData({...AllData,[e.target.name]:e.target.value})} size='small' label="Kg Entrada" variant="outlined" />
                    </div>
                    <div className='col-12 py-2'>
                    <TextField id="outlined-basic" name='kgout' type={'number'} value={AllData.kgout} fullWidth onChange={(e)=>setAllData({...AllData,[e.target.name]:e.target.value})} size='small' label="Kg Salida" variant="outlined" />
                    </div>
                    <div className='col-12 py-2'>
                    <TextField id="outlined-basic" name='xinyect' focused disabled type={'text'} value={AllData.porin+' %'} onChange={(e)=>console.log()} fullWidth size='small' label="% Inyeccion" variant="outlined" />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 pt-5'>
                <img src='./img/inyect.gif' className='img-fluid' />
                <label id="kiloin" className="kiloin"><small style={{fontSize:8}}>PESO ENTRADA</small> <br/>{AllData.kgin} kg</label>
                <label id="kiloout" className="kiloout"><small style={{fontSize:8}}>PESO SALIDA</small> <br/>{AllData.kgout} kg</label>
                <label id="porinye" className="porinye"><small style={{fontSize:8}}>PORCENTAJE</small> <br/>{AllData.porin} %</label>
                <label id="pressss" className="pressss"><small style={{fontSize:8}}>PRESION</small> <br/>{AllData.press} bar</label>
                <label id="tempsalm" className={AllData.temp>3?"tempsalmhigh":"tempsalm"}><small style={{fontSize:8}}>TEMP. SALMUERA</small> <br/>{AllData.temp}°C</label>
              </div>
            </div>
          </div>

  

          {/*  */}
          <div className='col-12'>
            <div className='row'>
              <div className='col-12 col-md-4 col-lg-3 py-2'>
                <TextField id="outlined-basic" name='press' type={'number'} value={AllData.press} fullWidth onChange={(e)=>setAllData({...AllData,[e.target.name]:e.target.value})} size='small' label="Presion Equipo" variant="outlined" />
              </div>
              <div className='col-12 col-md-4 col-lg-3 py-2'>
                <TextField id="outlined-basic" name='gxm' type={'number'} value={AllData.gxm} fullWidth onChange={(e)=>setAllData({...AllData,[e.target.name]:e.target.value})} size='small' label="Golpes por Minuto" variant="outlined" />
              </div>
              <div className='col-12 col-md-4 col-lg-3 py-2'>
                <TextField id="outlined-basic" name='velcint' type={'number'} value={AllData.velcint} fullWidth onChange={(e)=>setAllData({...AllData,[e.target.name]:e.target.value})} size='small' label="Velocidad Cinta" variant="outlined" />
              </div>
              <div className='col-12 col-md-4 col-lg-3 py-2'>
                <TextField id="outlined-basic" name='temp' type={'number'} value={AllData.temp} fullWidth onChange={(e)=>setAllData({...AllData,[e.target.name]:e.target.value})} size='small' label="Temperatura Salmuera" variant="outlined" />
              </div>
              <div className='col-12 col-md-4 col-lg-3 py-2'>
                <TextField id="outlined-basic" name='supervisor' type={'text'} value={AllData.supervisor} fullWidth onChange={(e)=>setAllData({...AllData,[e.target.name]:e.target.value})} size='small' label="Nombre supervisor" variant="outlined" />
              </div>
            </div>
          </div>
          {/*  */}

            

        </div>
          <div className='col-12 pb-5'>
            <Button fullWidth variant="contained" disabled={IsComplete} onClick={()=>HamdleSaveDataInyeccion()}>Guardar</Button>
          </div>
          {/* <div className='col-12 mt-2 pb-5 text-right'>
            <Button
              variant="contained"
              onClick={()=>exportAsImage(exposrtRef.current, "scrst_ctrl")}
              color='secondary'
              disabled={IsComplete}
            >
              <LinkedCameraIcon />
            </Button>
          </div> */}
      </div>
    </>
  )
}
