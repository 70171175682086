import React, { useEffect, useState } from 'react'
//typeChart
import { MainChart } from '../chart/MainChart'
import { MainChartPie } from '../chart/MainChartPie'
import { Chart_HighChart } from '../chart/Chart_HighChart'
//queryChartsData
import { ChartPie_ixmlw } from '../../components/ApiCharts/ChartPie_ixmlw'
import { ChartArea_ixmlw } from '../../components/ApiCharts/ChartArea_ixmlw'
import { ChartArea_xusr } from '../../components/ApiCharts/ChartArea_xusr'
import { Chart_HC_line } from '../chart/Chart_HC_line'
import { CharArea_pxm } from '../../components/ApiCharts/CharArea_pxm'
import { ChartAreaFilter } from '../../components/ApiCharts/filterChart/ChartAreaFilter'
import { GetOf } from '../../components/ApiCharts/filterChart/GetOf.FilterChart'
import { ChartData_ofs } from '../../components/ApiCharts/ChartData_ofs'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

export const MainDashBoard = () => {
  // chart pie
  const [ixmlw, setiXmlw] = useState(null);
  const [isCharge_ixmlw, setisCharge_ixmlw] = useState(false);

  //chart area one
  const [area_ixmlw, setArea_ixmlw] = useState(null);
  const [isCharged_aIxmlw, setisCharged_aIxmlw] = useState(false);

  //chart area xusr
  const [area_xusr, setAreax_usr] = useState(null);
  const [isCharged_xusr,setIsCharged_xusr] = useState(false);

  //chart area pxm
  const [area_pxm, setArea_pxm] = useState(null)

  //chart Data of
  const [DataByOf, setDataByOf] = useState(null)
  
  // ***********************************************************
  const [ListOf, setListOf] = useState([]);
  const [ofSelect, setOfSelect] = useState('')
  const GetListOfs = async () => {
    const list = await GetOf()
    // console.log(list)
    setListOf(list)
  }

  useEffect(() => {
    GetListOfs()
  }, [])
  // ***********************************************************

  //load chart pie
  const loadChartIxmlw = async () => {
    const chart = await ChartPie_ixmlw()
    // console.log('chartisimo', chart)
    await setiXmlw(chart)
  }
  useEffect(() => {
    loadChartIxmlw()
  }, [])

  useEffect(() => {
    setisCharge_ixmlw(true)
  }, [ixmlw])

  //load chart area one
  const loadChartAreaIxmlw = async () => {
    const chart = await ChartArea_ixmlw()
    // console.log('chartisimo', chart)
    await setArea_ixmlw(chart)
  }
  useEffect(() => {
    loadChartAreaIxmlw()
  }, [])
  useEffect(() => {
    setisCharged_aIxmlw(true)
  }, [ixmlw])

  //chart area xusr
  //load chart pie
  const loadChartIxusr = async () => {
    const chart = await ChartArea_xusr()
    // console.log('chartisimo', chart)
    await setAreax_usr(chart)
  }
  useEffect(() => {
    loadChartIxusr()
  }, [])

  useEffect(() => {
    setIsCharged_xusr(true)
  }, [area_xusr])

  const loadChartpxm = async () => {
    const chart = await CharArea_pxm()
    await setArea_pxm(chart)
  }
  useEffect(() => {
    loadChartpxm()
  }, [])

  const loadChartOFs = async () => {
    const data_ = await ChartData_ofs({ofSelect})
    await setDataByOf(data_)
  }
  useEffect(() => {
    loadChartOFs()
  }, [ofSelect])
  
  
  
  
  return (
    <>
      <div className='container-fluid fondo mb-3'>
        <div className='row pt-2'>
        <div className='col-12'>
            <FormControl sx={{ m: 1, minWidth: 120 }}fullWidth>
              <InputLabel id="demo-controlled-open-select-label" size='small'>Seleccione OF a Graficar</InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                label="Seleccione OF a Graficar"
                size='small'
                name='MAQUINA'
                value={ofSelect}
                onChange={(val)=>setOfSelect(val.target.value)}
              >
                {
                  ListOf.map((mqn, key)=><MenuItem key={key} style={{fontSize: '12px'}} value={mqn._id}>{mqn._id}</MenuItem>)
                }
                
              </Select>
            </FormControl>
          </div>
          <div className='col-12 mb-2'>
            <Chart_HC_line
              data={DataByOf}
              titleChart={'(OF) Orden de Fabricacion'}
            />
          </div>
          {/* grafico filtrado */}
          {/* <div className='col-12'>
            <ChartAreaFilter />
          </div> */}

          {/* <div className='col-12'>
            <h1>Main View</h1>
          </div>
          <div className='col-6'>
            {isCharge_ixmlw?<MainChartPie
              titleChart={'Inyecciones por Maquina Ultima semana'}
              data={ixmlw}
            />:null
            }
          </div> */}

          {/* <div className='col-6'>
            <MainChartPie titleChart={'Inyecciones por Producto'} />
          </div> */}
          {/* <div className='col-6'>
            {
              isCharged_aIxmlw?<MainChart
                titleChart={'Inyecciones por Usuario'}
                data={area_ixmlw}
              />:null
            }
          </div> */}
          <div className='col-12 mb-2'>
            <Chart_HighChart
              data={area_ixmlw}
              titleChart={'Maquinas'}
            />
          </div>
          <div className='col-12 mb-2'>
            <Chart_HighChart
              data={area_xusr}
              titleChart={'Usuarios'}
            />
          </div>

          <div className='col-12 mb-2'>
            <Chart_HighChart
              data={area_pxm}
              titleChart={'Productos'}
            />
          </div>
        </div>
      </div>
    </>
  )
}
