import {GetData,SetData} from '../types'

export default function (state, action) {
  const {payload, type} = action;

  switch (type) {
    case GetData:
      return {
        ...state,
        data: payload
      }
      
    case SetData:
      return {
        ...state,
        data: payload
      }
    
    default:
      return state
  }
}