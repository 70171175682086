import React, {useState, useEffect, useContext} from 'react'
import './main.css'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom"
import Button from '@mui/material/Button';
import DataContext from '../context/Data/DataContext';
import LoginContext from '../context/Login/LoginContext';
import { DashBoard } from './admin/DashBoard.view';
import { GetUserSelect } from '../components/GetUserSelect';

export const Main = () => {

  const [HaveSession, setHaveSession] = useState('');
  const {state, setData} = useContext(DataContext);
  const {LoginState, SetLogin} = useContext(LoginContext);
  const navigate = useNavigate();
  const [currency, setCurrency] = useState({
    usuario: '',
    maquina: '',
    linea: '',
    equipo: ''
  });
  const [IsDeseable, setIsDeseable] = useState(false);
  const [userListS_, setUserListS_] = useState([]);

  const handleChange = (e) => {
    if(e.target.name === 'maquina'){
      let atmos = e.target.value.split('_')
      setCurrency({
        ...currency,
        ["linea"]: atmos[0],
        ["equipo"]: atmos[1],
        [e.target.name]: e.target.value
      });
      sessionStorage.setItem('equipo', atmos[1] )
      sessionStorage.setItem('linea', atmos[0])
      sessionStorage.setItem('maquina', e.target.value)
    }else{
      setCurrency({...currency, [e.target.name]: e.target.value});
      sessionStorage.setItem('usuario', e.target.value)
    }
    // console.log(e.target.name, e.target.value)
    HandleIsDisable()
  };

  const HandleIsDisable = () => {
    
    if(currency.equipo !== '' && currency.usuario !== ''){
      setIsDeseable(false)
      // console.log('validacion del boton')
    }else{
      setIsDeseable(true)
    }
  }

  useEffect(() => {
    HandleIsDisable()
  }, [currency])
  
  // useEffect(() => {
  //   const user = sessionStorage.getItem('user') || 'no-user'
  //   const lslgn = sessionStorage.getItem('lastLogin') || '0'
  //   SetLogin(user, lslgn)
  // }, [])
  

  const HandleGoTO = () => {
    setData(currency)
    navigate("../seleccion", { replace: true });
    // console.log(currency)
  }

  const getUsersSelect = async () => {
    const users_ = await GetUserSelect()
    // console.log('userSelect', users_.body);
    setUserListS_(users_.body)
  }

  useEffect(() => {
    getUsersSelect();
    const user = sessionStorage.getItem('user');
    if(user){
      setHaveSession(user)
    }
  }, [])

  useEffect(() => {
    
  }, [])
  

  return (
  <>
    <div className='container-fluid'>
      <div className='row fullhd'>
        <div className='centro w-75'>
          <div className='row'>
            <div className='centro text-center col-12 col-md-6'>
              <img src="/img/logo_ariztia.png" alt="" />
            </div>
            <div className='fondo col-12 col-md-6 divmenu1'>
              <div className='row py-5'>
                <div className='col-12 py-3'>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Seleccione Usuario"
                    name='usuario'
                    size='small'
                    fullWidth
                    value={currency.usuario}
                    onChange={handleChange}
                    // helperText="Please select your currency"
                  >
                    {
                      userListS_.map(usr=><MenuItem key={usr._id} style={{fontSize: 'small', fontFamily: 'monospace'}} value={usr.NOMBRE}>{usr.NOMBRE}</MenuItem>)
                    }
                  </TextField>
                </div>
                <div className='col-12 py-3'>
                  <TextField
                    select
                    id="outlined-select-currency"
                    label="Seleccione Maquina"
                    name='maquina'
                    size='small'
                    fullWidth
                    value={currency.maquina}
                    onChange={handleChange}
                  >
                    <MenuItem value='IQF_FOMACO FGM88 IQF 1'>FOMACO FGM88 IQF 1</MenuItem>
                    <MenuItem value='IQF_CFS 650 IQF 4'>CFS 650 IQF 4</MenuItem>
                    <MenuItem value='ISHIDA_CFS 650 TRUTRO NORTE'>CFS 650 TRUTRO NORTE</MenuItem>
                    <MenuItem value='INYEC_FOMACO FGM112 TRUTRO SUR'>FOMACO FGM112 TRUTRO SUR</MenuItem>
                  </TextField>
                </div>
                <div className='col-12 py-3'>
                  <Button
                    disabled={IsDeseable}
                    variant="contained"
                    fullWidth size='small'
                    onClick={()=>HandleGoTO()}
                  >
                    Ingresar
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
      <div className='float-login'>
        {
          HaveSession?<p onClick={()=>navigate('/dashboard')}>🖐🏽 {HaveSession}</p>:
          <p onClick={()=>navigate('/login')}>Login Here 👈🏽</p>
        }
        </div>
    </>
  )
}
