import React from 'react'
import { url } from '../link/Url.link';

export const GetUsersInyect = () => {
  return new Promise(async function(resolve, reject) {
    try {
      const query = await fetch(url+'/getuserinyect',{
        method: 'POST',
        headers: {
          'authorization': "marcuxo",
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })

        const reqkkst = await query.json();
        
        if(reqkkst.success){
          resolve(reqkkst)
        }else{
          resolve(reqkkst.error)
          alert('Upss!! ocurrio un error vuelve a intentarlo');
        }
      } catch (error) {
        resolve(null)
      }
  })
}
