import { Button, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LoginContext from '../../context/Login/LoginContext'
import './login.css';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PasswordIcon from '@mui/icons-material/Password';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

export const Login = () => {
  const navidation = useNavigate()
  const {LoginState, SetLogin, SetLoginDirect} = useContext(LoginContext);

  const [session, setSession] = useState({
    user: '',
    password: ''
  })
  const [IsDisable, setIsDisable] = useState(true)

  const HandleSetSession = async (event) => {
    // console.log(event.target.name,event.target.value)
    setSession({
      ...session,
      [event.target.name]: event.target.value.toLowerCase().trim()
    })
  }

  const HandleIsDisable = () => {
    if(session.user !== '' && session.password !== ''){
      setIsDisable(false)
      // console.log('validacion del boton')
    }else{
      setIsDisable(true)
    }
  }

  const HandleLogin = async ()  => {
    const isLogin = await SetLogin(session,Date.now());
    // console.log('isLogin',isLogin);
    if(isLogin)navidation('/dashboard')
    else{alert('los datos ingresados son ERRONEOS::')}
    //
  }

  useEffect(() => {
    const isthis = async () => {
      const isSession = await SetLoginDirect();
      if(isSession){
        navidation('/dashboard');
      }
    }
    isthis()
  }, [])

  useEffect(() => {
    HandleIsDisable()
  }, [session])
  
  return (
    <>
      {/* login skin */}
      <div className='login-skin'></div>


      <div className='container-fluid'>
      <div className='row fullhd'>
        <div className='centro w-75'>
          <div className='row'>
            <div className='centro-logo text-center col-12 col-md-6 p-5'>
              <img className='cursor' src="/img/logo.svg" alt=""  onClick={()=>navidation("/", { replace: true })}/>
            </div>
            <div className='fondo col-12 col-md-6 divmenu-login mt-5'>
              <div className='row pb-4'>
                <div className='col-12 text-center'>
                  {/* <AccountCircleOutlinedIcon style={{fontSize: '100px', rotate:'0deg',position: 'relative', top: '-30px', backgroundColor: '#fff', borderRadius:'50px'}} /> */}
                  <span style={{fontSize: '75px',position: 'relative', top: '-40px', backgroundColor: '#fff', borderRadius:'50%', padding: '0px 20px 20px 20px', border: '5px solid white', boxShadow: 'inset 0px 10px 5px -2px rgb(0,0,0,.3)'}}>
                    <img src='/img/chicken.png' height={'90px'} />
                  </span>
                </div>
                <div className='col-12 pb-3'>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <TextField
                      id="input-with-sx-1"
                      label="Usuario"
                      variant="standard"
                      name='user'
                      value={session.user}
                      onChange={(e)=>HandleSetSession(e)}
                      fullWidth />
                  </Box>
                </div>
                <div className='col-12'>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <PasswordIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <TextField
                      type={'password'}
                      id="input-with-sx-2"
                      label="Contraseña"
                      variant="standard"
                      name='password'
                      value={session.password}
                      onChange={(e)=>HandleSetSession(e)}
                      fullWidth />
                  </Box>
                </div>
                <div className='col-12 pt-3'>
                  <Button
                    variant="contained"
                    fullWidth size='small'
                    style={{borderRadius: '15px', padding: '10px 0px'}}
                    onClick={()=>HandleLogin()}
                    disabled={IsDisable}
                  >
                    Ingresar
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>

    </>
  )
}
